import styled from "styled-components"

export const BtnGeneral = styled.a`
  font-family: 'DM Sans';
  padding: 14px 70px;
  border: 2px solid white;
  background-color: white;
  color: black;
  cursor: pointer;
  position: relative;
  transform: translate(12px);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  & .arrow-btn {
    position: absolute;
    padding-left: 40px;
    width: 15px;
  }
  &:after {
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 1px;
  left: -12px;
  right: 1px;
  bottom: -12px;
  border: 4px solid white;
  z-index: -1;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    transform: translate(17px, -5px);
    background: #fff;
    color: #010606;
  }
  &:hover:after {
  transition: all 0.2s ease-in-out;
  content: '';
  position: absolute;
  top: 6px;
  left: -17px;
  right: 6px;
  bottom: -17px;
  border: 4px solid white;
  z-index: -1;
  }

  @media (max-width: 760px) {
    padding: 14px 20px;
  }
`