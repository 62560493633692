/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, useState } from 'react';
import {
  Image,
  Main,
  Header_Log,
  ContainerHeader,
  HeaderText,
  ContainerDivHeader,
  ContainerHeaderMobile,
  MenuWrapper,
  MenuItem,
  ButtonLink,
} from './containers/common.styled';
import ImageLogo from './../assets/img/isotipo-color.png';
import ImageLogoMobile from './../assets/img/isotipo.svg';
import BurguerMenu from './../assets/img/menu.png';

const Header = (props) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleEvent = (name) => {
    if (props.onEvent) {
      props.onEvent(name);
    }
  };

  return (
    <Fragment>
      <Main
        padding="1rem"
        style={{ background: '#00446a33', marginTop: '-8px' }}
      >
        <ContainerHeader>
          <div className="m-auto">
            <Image
              src={ImageLogo}
              width="80%"
              alt="logo-coru"
              margin="inherit"
              ml="4%"
              top="5%"
            />
          </div>
          <Header_Log className="text-session">
            <ContainerDivHeader>
              <HeaderText href="#divStart">Home</HeaderText>
              <HeaderText href="#whatWeDo">Services</HeaderText>
              <HeaderText href="#ourFootprint">About</HeaderText>
              <ButtonLink
                href="#divForm"
                mt="0%"
                mb="0px"
                fs="12px"
                width="30%"
                height="40px"
                ml="5%"
                onClick={() => handleEvent('click-form-header')}
              >
                Let’s growth together!
              </ButtonLink>
            </ContainerDivHeader>
          </Header_Log>
        </ContainerHeader>
        <ContainerHeaderMobile>
          <div className="m-auto">
            <Image
              src={ImageLogoMobile}
              width="52%"
              alt="logo-coru"
              marginMobile="inherit"
              mlMobile="-17%!important"
            />
          </div>
          <Header_Log
            id="divmenumobile"
            className="text-session"
            onClick={handleClick}
          >
            <Image
              src={BurguerMenu}
              width="10%"
              alt="logo-coru"
              widthMobile="30px"
              marginMobile="inherit"
              mlMobile="auto"
            />
            <MenuWrapper open={open}>
              <MenuItem href="/">Home</MenuItem>
              <MenuItem href="/">Services</MenuItem>
              <MenuItem href="/">About</MenuItem>
            </MenuWrapper>
          </Header_Log>
        </ContainerHeaderMobile>
      </Main>
    </Fragment>
  );
};

export default Header;
