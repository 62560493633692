import styled from 'styled-components';
import { device } from '../devise';
import BackDroneLetters from '../../assets/img/weDeliver.svg';
import BackDroneLettersMobile from '../../assets/img/weDeliverMobile.svg';
import {
  imageComponent,
  buttonWhatsApp,
  LogoWhatsApp,
  Hexagono,
} from './common';

export const Container = styled.div`
  width: ${(props) => props.width || '70%'};
  margin: ${(props) => props.float || 'auto'};
  margin-top: ${(props) => props.mt || 'auto'};
  margin-bottom: ${(props) => props.mb || 'auto'};
  margin-left: ${(props) => props.ml || 'auto'};
  text-align: ${(props) => props.ta || ''};
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  @media (max-width: 800px) {
    flex: 0 0 85%;
    max-width: 85%;
    margin: auto !important;
    margin-top: ${(props) => props.mtMobile || 'auto'};
    margin-bottom: ${(props) => props.mbMobile || 'auto'};
  }
`;

export const Main = styled.main`
  width: ${(props) => props.width};
  height: ${(props) => props.heightComponent || 'auto!important'};
  padding: ${(props) => props.padding || '0rem'};
  text-align: ${(props) => props.ta || 'left'};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: ${(props) => props.background || '#fff'};
`;

export const Col6 = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding: 0px 5px;
  text-align: ${(props) => props.ta || 'left'};
  padding-top: ${(props) => props.pt || '0%'};
  margin: ${(props) => props.margin || ''};
  @media (max-width: 800px) {
    flex: 0 0 98%;
    max-width: 98%;
  }
`;

export const Col8 = styled.div`
  flex: 0 0 65%;
  max-width: 65%;
  padding: 0px 5px;
  text-align: ${(props) => props.ta || 'left'};
  padding-top: ${(props) => props.pt || '0%'};
  margin: ${(props) => props.margin || ''};
  @media (max-width: 800px) {
    flex: 0 0 98%;
    max-width: 98%;
  }
`;

export const Col2 = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0px 5px;
  text-align: ${(props) => props.ta || 'left'};
  padding-top: ${(props) => props.pt || '0%'};
  margin: ${(props) => props.margin || ''};
  @media (max-width: 800px) {
    flex: 0 0 98%;
    max-width: 98%;
  }
`;

export const ParagraphOne = styled.p`
  text-align: ${(props) => props.ta || 'left'};
  font-size: ${(props) => props.fontSize || '40px'};
  margin-top: ${(props) => props.mt || '35px'};
  margin-bottom: ${(props) => props.mb || '0px'};
  color: ${(props) => props.color || '#000b49'};
  font-weight: ${(props) => props.fontWeight || 'bold'};
  cursor: ${(props) => props.cursor || 'default'};
  width: ${(props) => props.width || '100%'};
  line-height: ${(props) => props.lh || '38px'};
  padding-left: ${(props) => props.pl || ''};
  font-family: 'Jura', sans-serif;
  @media (max-width: 800px) {
    font-size: ${(props) => props.fontSizeMobile || '30px'};
    line-height: ${(props) => props.lhmobile || '40px'};
    margin-bottom: ${(props) => props.mbMobile || ''};
    text-align: ${(props) => props.tam || ''};
  }
`;

export const ParagrapTwo = styled.p`
  text-align: ${(props) => props.ta || 'left'};
  font-size: ${(props) => props.fontSize || '16px'};
  margin-top: ${(props) => props.mt || '0px'};
  margin-bottom: ${(props) => props.mb || '9px'};
  color: ${(props) => props.color || '#EBF0F4'};
  font-weight: ${(props) => props.fontWeight || '400'};
  line-height: ${(props) => props.lh || '22px'};
  cursor: ${(props) => props.cursor || 'default'};
  width: ${(props) => props.width || '100%'};
  padding-left: ${(props) => props.pl || ''};
  font-family: ${(props) => props.ff || '"Raleway", sans-serif'};
  @media (max-width: 800px) {
    margin-top: ${(props) => props.mtMobile || '0px'};
    margin-bottom: ${(props) => props.mbMobile || '9px'};
    font-size: ${(props) => props.fontSizeMobile || '16px'};
    line-height: ${(props) => props.lhmobile || ''};
    padding-left: 0px;
    text-align: ${(props) => props.tam || ''};
  }

  @media (min-width: 1411px) and (max-width: 1510px) {
    width: ${(props) => props.widthmax3 || '100%'};
  }

  @media (min-width: 1511px) {
    width: ${(props) => props.widthmax4 || '100%'};
  }
  @media (min-width: 1640px) {
    width: ${(props) => props.widthmax5 || '100%'};
  }
  @media (min-width: 1758px) {
    width: ${(props) => props.widthmax6 || '100%'};
  }

  @media (min-width: 2119px) and (max-width: 2404px) {
    width: ${(props) => props.widthmax || '100%'};
  }
  @media (min-width: 2405px) {
    width: ${(props) => props.widthmax2 || '100%'};
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.bgColor || 'rgb(187,38,163)'};
  color: ${(props) => props.color || '#fff'};
  box-sizing: border-box;
  border: none;
  width: ${(props) => props.width || '50%'};
  padding: 8px;
  border-radius: 5px;
  height: ${(props) => props.height || '47px'};
  font-size: ${(props) => props.fs || '18px'};
  margin: ${(props) => props.margin || ''};
  margin-top: ${(props) => props.mt || '5%'};
  margin-bottom: ${(props) => props.mb || '5%'};
  margin-left: ${(props) => props.ml || ''};
  float: ${(props) => props.float || ''};
  cursor: pointer;
  @media (max-width: 800px) {
    flex: 0 0 85%;
    width: 100%;
    margin: auto !important;
    margin-top: ${(props) => props.mtm || '10% !important'};
    margin-bottom: 10% !important;
    float: center;
  }
`;

export const ButtonLink = styled.a`
  background-color: ${(props) => props.bgColor || 'rgb(255,255,255)'};
  color: ${(props) => props.color || '#1A6745'};
  box-sizing: border-box;
  border: ${(props) => props.border || ''};
  width: ${(props) => props.width || '50%'};
  padding: ${(props) => props.padding || '10px'};
  border-radius: 5px;
  height: ${(props) => props.height || '47px'};
  font-size: ${(props) => props.fs || '18px'};
  margin-top: ${(props) => props.mt || '5%'};
  margin-bottom: ${(props) => props.mb || '5%'};
  margin-left: ${(props) => props.ml || '0px'};
  float: ${(props) => props.float || ''};
  display: ${(props) => props.display || ''};
  align-items: ${(props) => props.alingItems || ''};
  justify-content: ${(props) => props.justifyContent || ''};
  text-align: center;
  text-decoration: none;
  font-family: 'Jura', sans-serif;

  @media (max-width: 600px) {
    flex: 0 0 85%;
    width: ${(props) => props.wm || '100%'};
    margin: ${(props) => props.mm || ''};
    margin-top: ${(props) => props.mtm || '10% !important'};
    margin-bottom: ${(props) => props.mbm || '10% !important'};
    float: center;
  }
`;

export const ButtonWhatsApp = styled(buttonWhatsApp)`
  background-color: ${(props) => props.bgColor || '#1A6745'};
  color: ${(props) => props.color || '#fff'};
  box-sizing: border-box;
  border: none;
  width: ${(props) => props.width || '10%'};
  padding: ${(props) => props.padding || '10px'};
  border-radius: ${(props) => props.borderRadius || '25px'};
  height: ${(props) => props.height || '47px'};
  font-size: ${(props) => props.fs || '18px'};
  margin-top: ${(props) => props.mt || ''};
  margin-bottom: ${(props) => props.mb || ''};
  margin-left: ${(props) => props.ml || '0px'};
  float: ${(props) => props.float || ''};
  display: ${(props) => props.display || 'flex'};
  align-content: center;
  justify-content: flex-end;
  right: ${(props) => props.right || ''};
  left: ${(props) => props.left || ''};
  bottom: ${(props) => props.bottom || ''};
  top: ${(props) => props.top || ''};
  position: ${(props) => props.position || ''};
  text-align: ${(props) => props.textAlign || ''};
  transition-duration: 0.5s;
  z-index: 10;
  &:hover {
    cursor: pointer;
    width: 20%;
    justify-content: space-between;
    transition-duration: 0.5s;
  }
  @media (max-width: 800px) {
    flex: 0 0 85%;
    width: 15%;
    margin: auto !important;
    &:hover {
      cursor: pointer;
      width: 65%;
      transition-duration: 0.5s;
    }
  }
`;
export const HexagonoCard = styled(Hexagono)`
  position: relative;
  display: ${(props) => props.display || 'flex'};
  background-color: ${(props) => props.bgColor || '#d7e1e6'};
  z-index: ${(props) => props.zIndex || '1'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  flex-direction: ${(props) => props.flexDirection || 'column'};
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
    transform: rotate(60deg);
    background-color: inherit;
    z-index: -1;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    transform: rotate(-60deg);
    background-color: inherit;
    z-index: -1;
  }
`;

export const LogoWhats = styled(LogoWhatsApp)``;
export const Image = styled(imageComponent)`
  max-width: 100%;
  width: ${(props) => props.width || '85%'};
  display: flex;
  margin: ${(props) => props.margin || 'auto'};
  margin-bottom: ${(props) => props.bottom || ''};
  margin-top: ${(props) => props.top || ''};
  height: ${(props) => props.heigth || 'auto'};
  margin-left: ${(props) => props.ml || ''};
  @media (max-width: 800px) {
    flex: 0 0 50%;
    width: ${(props) => props.widthMobile || '50%'};
    margin: ${(props) => props.marginMobile || 'auto!important'};
    margin-top: ${(props) => props.mtMobile || '0%'};
    margin-bottom: ${(props) => props.mbMobile || '0%'};
    margin-left: ${(props) => props.mlMobile || '0%'};
    height: ${(props) => props.heigthMobile || 'auto'};
    display: ${(props) => props.dm || 'flex'};
  }

  @media ${device.tablet} {
    display: ${(props) => props.dt || 'flex'};
  }
`;
export const Col12 = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px 5px;
  text-align: ${(props) => props.ta || 'left'};
  padding-top: ${(props) => props.pt || '7%'};
  display: ${(props) => props.display || 'block'};
  @media (max-width: 800px) {
    display: block;
  }
`;

export const Col3 = styled.div`
  flex: 0 0 17%;
  max-width: 17.5%;
  padding: 2% 3%;
  text-align: ${(props) => props.ta || 'center'};
  border: 1px solid #d7e1e6;
  grid-column: span 12;
  -webkit-box-shadow: 6px 6px 15px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 15px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 15px -3px rgba(0, 0, 0, 0.75);
  height: 170px;
  margin: auto;
  margin-left: ${(props) => props.ml || 'auto'};
  line-height: ${(props) => props.lh || 'auto'};
  @media (max-width: 800px) {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 15%;
    padding: 5%;
    margin-left: ${(props) => props.mlMobile || 'auto'};
    margin-top: ${(props) => props.mtMobile || '0px'};
  }
`;

export const Col3Contor = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  padding: 1% 2%;
  text-align: ${(props) => props.ta || 'center'};
  border-right: ${(props) => props.border || ''};
  grid-column: span 12;
  height: auto;
  line-height: ${(props) => props.lh || 'auto'};
  @media (max-width: 800px) {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 15%;
    padding: 5%;
    margin: auto;
    margin-top: ${(props) => props.mtMobile || 'auto'};
    border-right: 0px;
    border-bottom: ${(props) => props.border || '2px solid rgb(187,38,163)'};
    padding-bottom: 15% !important;
  }
`;

export const SpanCoru = styled.span`
  color: rgb(187, 38, 163) !important;
`;

export const Header_Log = styled.div`
  color: #000b49;
  font-weight: 600;
  width: 42%;
  float: right;
  margin-left: auto;
  min-width: 50%;
  text-align: right;
`;

export const HeaderText = styled.a`
  color: #00446a !important;
  text-decoration: none;
  font-size: 16px;
  padding: 2%;
  margin-top: 1%;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
`;

export const Linktext = styled.a`
  color: rgb(187, 38, 163) !important;
  text-decoration: none;
  font-size: 10px;
  width: 100%;
`;

export const Spanticons = styled.span`
  color: rgb(187, 38, 163) !important;
  font-size: 10px;
  float: right;
  margin-top: 3%;
  padding-right: 15%;
`;

export const ContainerHeader = styled.div`
  width: 71%;
  display: flex;
  @media (max-width: 801px) {
    display: none;
  }
`;

export const ContainerHeaderMobile = styled.div`
  width: 90%;
  display: flex;
  @media (min-width: 801px) {
    display: none;
  }
`;

export const MenuToggle = styled.div`
  z-index: 9999;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
  cursor: pointer;
  margin-left: auto;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: ${(props) =>
      props.open ? 'all 0.25s ease-in' : 'all 0.25s ease-out'};
  }
  span:nth-child(1) {
    top: ${(props) => (props.open ? 'calc(50% - 2px)' : '10%')};
    transform-origin: left center;
  }
  span:nth-child(2) {
    top: ${(props) => (props.open ? 0 : 'calc(50% - 2px)')};
    left: ${(props) => (props.open ? 'calc(50% - 2px)' : null)};
    width: ${(props) => (props.open ? '4px' : null)};
    height: ${(props) => (props.open ? '100%' : null)};
    transform-origin: left center;
  }
  span:nth-child(3) {
    top: calc(90% - 4px);
    transform-origin: left center;
    width: ${(props) => (props.open ? 0 : null)};
    opacity: ${(props) => (props.open ? 0 : 1)};
  }
`;

export const Menu = styled.div`
  height: 100%;
  width: 100%;
  transition: ${(props) =>
    props.open ? 'all 0.25s ease-in-out' : 'all 0.25s ease-in-out'};
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'none')};
`;

export const MenuWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  top: ${(props) => (props.open ? '0' : '-100%')};
  left: 0;
  z-index: 0;
  margin-top: 63px;
  width: 100%;
  transition: ${(props) =>
    props.open ? 'all 0.25s ease-out' : 'all 0.6s ease-out'};
  padding: 12px;
  height: 100px;
  background: #cfd9e2;
  z-index: 101;
  border-top: 2px solid #1b6745;
`;

export const MenuItem = styled.a`
  display: block;
  width: 80%;
  padding: 2%;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
`;

export const ContainerDivHeader = styled.div`
  display: block;
  width: 100%;
  height: 51.05px;
`;

export const Title1 = styled.p`
  display: block;
  width: 100%;
  margin: auto;
`;

export const ContainerImagenCode = styled.div`
  max-width: 450px;
  margin-left: -65%;
  width: 450px;
  display: none;

  @media ${device.tablet} {
    display: block;
    margin-left: 35%;
    margin-top: -26%;
  }

  @media ${device.laptop} {
    max-width: 450px;
    margin-left: -65%;
    width: 450px;
    margin-top: 0%;
  }

  @media ${device.laptopL} {
    max-width: 650px;
    margin-left: -75%;
    width: 650px;
    margin-top: 0%;
  }
`;

export const BackTriangle = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    position: absolute;
    right: 0%;
    top: 11%;
  }
`;

export const Triangle = styled.div`
  display: none;

  @media ${device.tablet} {
    position: absolute;
    right: 5%;
    z-index: 99;
    top: 45%;
    display: block;
  }

  @media ${device.laptop} {
    top: 34%;
  }

  @media ${device.laptopL} {
    top: 41.5%;
  }

  @media ${device.desktop} {
    top: 29%;
  }
`;

export const CardServices = styled.div`
  height: 245px;
  padding: 30px 10px;
  border: 0px solid #000000;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  background: ${(props) => props.bg || '#fff'};
  border-top-right-radius: ${(props) => props.btrr || '0'};
  border-top-left-radius: ${(props) => props.btlr || '0'};
  border-bottom-left-radius: ${(props) => props.bblr || '0'};
  border-bottom-right-radius: ${(props) => props.bbrr || '0'};
  left: ${(props) => props.left || ''};
  position: ${(props) => props.position || ''};

  @media ${device.tablet} {
    height: ${(props) => props.ht || ''};
  }

  @media ${device.laptop} {
    height: 245px;
    padding: 10px 10px;
    border-bottom-right-radius: ${(props) => props.bbrrlap || '0'};
  }

  @media ${device.laptopL} {
    padding: 30px 10px;
    border-bottom-right-radius: ${(props) => props.bbrrlapl || '0'};
  }
`;

export const ContainerCardsServices = styled.div`
  width: 25%;
  max-width: 250px;
  height: 490px;
  margin-top: ${(props) => props.mt || ''};
  margin-left: ${(props) => props.ml || ''};
`;

export const BackDrone = styled.main`
  width: ${(props) => props.width};
  height: ${(props) => props.heightComponent || 'auto!important'};
  padding: ${(props) => props.padding || '0rem'};
  text-align: ${(props) => props.ta || 'left'};
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: ${(props) => props.background || '#fff'};
  padding-top: ${(props) => props.ptm || ''};
  margin-top: ${(props) => props.mtm || ''};
  background-image: url(${BackDroneLettersMobile});
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 40%;

  @media ${device.tablet} {
    background-image: url(${BackDroneLetters});
    background-position-y: 75%;
  }

  @media ${device.laptop} {
    background-image: url(${BackDroneLetters});
    padding-top: 300px;
    margin-top: -277px;
  }

  @media ${device.laptopL} {
    padding-top: 250px;
    margin-top: -289px;
  }
`;

export const Flex = styled.div`
  display: ${(props) => props.display || 'block'};
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media ${device.tablet} {
    justify-content: ${(props) => props.jct || 'space-between'};
  }

  @media ${device.laptop} {
    display: inline-flex;
    justify-content: space-between;
  }
`;

export const ContainerServices = styled.div`
  text-align: center;
  width: 1074px;
  max-width: 1074px;
  display: none;
  margin: auto;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0px 5px;
  text-align: left;
  padding-top: 150px;
  justify-content: center;

  @media ${device.laptop} {
    display: inline-flex;
  }
`;

export const TriangleMobile = styled.div`
  display: block;
  position: absolute;
  top: 10%;
  right: 0;

  @media ${device.tablet} {
    display: none;
  }
`;

export const Chip = styled.div`
  width: 80%;
  margin-top: 15%;
  position: absolute;
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;

export const WhatWeDo = styled.div`
  width: 100%;
  display: block;
  margin-top: 10%;

  @media ${device.laptop} {
    display: none;
  }
`;
