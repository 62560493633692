/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { animated, useSpring } from 'react-spring';

import TagManager from 'react-gtm-module';

import {
  ButtonLink,
  Container,
  Main,
  Col6,
  ParagraphOne,
  ParagrapTwo,
  Button,
  Image,
  Col12,
  ButtonWhatsApp,
  LogoWhats,
  Col2,
  Col8,
  ContainerImagenCode,
  BackTriangle,
  Triangle,
  CardServices,
  ContainerCardsServices,
  BackDrone,
  Flex,
  ContainerServices,
  TriangleMobile,
  Chip,
  WhatWeDo,
} from '../components/containers/common.styled';
import ImageLogo from './../assets/img/code.svg';
import ImageTwo from './../assets/img/imagen2.png';
//imagenes figuras geometricas
import DataAnalytics from './../assets/img/icons/dataAnalytics.svg';
import ContentMarketing from './../assets/img/icons/contentMarketing.svg';
import ReferralPrograms from './../assets/img/icons/referralPrograms.svg';
import MarketingAutomation from './../assets/img/icons/marketingAutomation.svg';
import DigitalAdquisicion from './../assets/img/icons/digitalAdquisition.svg';
import ProductStrategy from './../assets/img/icons/productStrategy.svg';
import PerformanceAnalysis from './../assets/img/icons/performanceAnalysis.svg';
import PredictiveMarketing from './../assets/img/icons/predictiveMarketing.svg';
import DataAnalyticsMobile from './../assets/img/icons/DA.svg';
import ContentMarketingMobile from './../assets/img/icons/CM.svg';
import ReferralProgramsMobile from './../assets/img/icons/RP.svg';
import MarketingAutomationMobile from './../assets/img/icons/MA.svg';
import DigitalAdquisicionMobile from './../assets/img/icons/DIA.svg';
import ProductStrategyMobile from './../assets/img/icons/PS.svg';
import PerformanceAnalysisMobile from './../assets/img/icons/PA.svg';
import PredictiveMarketingMobile from './../assets/img/icons/PM.svg';
//imagenes figuras geometricas
import ImageThre from './../assets/img/dron.svg';
import ShadowDron from './../assets/img/shadowDron.svg';
import Isotipo from './../assets/img/isotipo.svg';
import ImagePhone from './../assets/img/imagePhone.svg';
import Face from './../assets/img/icons/face.png';
import Insta from './../assets/img/icons/Instagram.png';
import Linkedin from './../assets/img/icons/linke.png';
import ImageTriangleMobile from './../assets/img/triangleMobile.svg';
import ImageChip from './../assets/img/chip.svg';
import './../assets/css/home.css';
import emailjs from 'emailjs-com';
import { BtnGeneral } from './BtnStyle';
import Backdrop from '@material-ui/core/Backdrop';
import { isMobile } from 'react-device-detect';
import ImageBackTriangle from '../assets/img/backTriangle.svg';
import ImageTriangle from '../assets/img/imgTringle.svg';

const currencies = [
  {
    value: 'Data',
    label: 'Data & Analytics',
  },
  {
    value: 'Content',
    label: 'Content Marketing',
  },
  {
    value: 'Referral',
    label: 'Referral Programs',
  },
  {
    value: 'Marketing',
    label: 'Marketing Automation',
  },
  {
    value: 'Digital',
    label: 'Digital Acquisition',
  },
  {
    value: 'Product',
    label: 'Product Strategy',
  },
  {
    value: 'Performance',
    label: 'Performance Analysis',
  },
  {
    value: 'Predictive',
    label: 'Predictive Marketing',
  },
];
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'inline-grid',
  },
}));

const Home = () => {
  const [toggle, set] = useState(true);

  const [currency, setCurrency] = React.useState('EUR');
  const [form, setForm] = useState({
    full_name: '',
    email: '',
    whatsapp: '',
    serie: '',
    role: '',
    company: '',
  });
  const [btndisabled, Setbtndisabled] = useState(true);
  const [textError, SettextError] = useState({
    full_name: '',
    email: '',
    whatsapp: '',
    serie: '',
    role: '',
    company: '',
  });
  const [error, setError] = useState({
    full_name: false,
    email: false,
    whatsapp: false,
    serie: false,
    role: '',
    company: '',
  });

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const [state, setState] = useState(true);
  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 1000 },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({
      full_name: '',
      email: '',
      whatsapp: '',
      serie: '',
    });
  };
  const ValidLetras = (nombre, valor) => {
    if (nombre && !/^[a-zA-Z*\s]+$/i.test(valor)) {
      textError[nombre] = 'Value cannot have numeric characters';
      error[nombre] = true;
    } else {
      textError[nombre] = '';
      error[nombre] = false;
    }
    eBtn();
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    switch (e.target.name) {
      case 'email':
        ValidEmail();
        break;
      case 'full_name':
        ValidLetras('full_name', form.full_name);
        break;
      case 'whatsapp':
        ValidPhone();
        break;
      case 'role':
        ValidLetras('role', form.role);
        break;
      case 'company':
        ValidLetras('company', form.company);
        break;
      default:
        //ValidLetras(e.target.name, e.target.value)
        break;
    }
  };

  const ValidPhone = () => {
    if (
      form.whatsapp.length >= 10 ||
      ('whatsapp' && !/^[0-9]+$/i.test(form.whatsapp))
    ) {
      textError.whatsapp = 'Please enter a 10-digit phone number.';
      error.whatsapp = true;
    } else {
      textError.whatsapp = '';
      error.whatsapp = false;
    }
    eBtn();
  };

  const ValidPhone2 = () => {
    if (
      form.whatsapp.length !== 10 ||
      ('whatsapp' && !/^[0-9]+$/i.test(form.whatsapp))
    ) {
      textError.whatsapp = 'Please verify your phone number';
      error.whatsapp = true;
    } else {
      textError.whatsapp = '';
      error.whatsapp = false;
    }
    eBtn();
  };

  const ValidEmail = () => {
    if (
      form.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)
    ) {
      textError.email = 'Email must be formatted correctly.';
      error.email = true;
    } else {
      textError.email = '';
      error.email = false;
    }
    eBtn();
  };

  const eBtn = () => {
    if (
      form.email.length !== '' &&
      !error.email &&
      form.full_name.length !== '' &&
      !error.full_name &&
      form.whatsapp.length !== '' &&
      !error.whatsapp &&
      form.serie.length !== '' &&
      !error.serie
    ) {
      Setbtndisabled(false);
    } else {
      Setbtndisabled(true);
    }
  };

  const guardar = (e) => {
    e.preventDefault();
    ValidPhone2();
    if (
      !error.email &&
      !error.full_name &&
      !error.whatsapp &&
      !error.serie &&
      !error.company &&
      !error.role
    ) {
      var template_params = {
        nombre: form.full_name,
        email: form.email,
        telefono: form.whatsapp,
        serie: form.serie,
      };

      var service_id = 'default_service';
      let user_id = 'user_zXxs8NJSj4QS32PtsdAZL';
      var template_id = 'envio_ali';
      emailjs.send(service_id, template_id, template_params, user_id).then(
        (response) => {
          //console.log('SUCCESS!', response.status, response.text);
          handleOpen();
          //setContact(frmContact);
          //setShowMessage(true);
        },
        (err) => {
          //console.log('FAILED...', err);
        }
      );
    } else {
    }
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <ParagraphOne mt="0px" fontSize="1.5rem" ta="center" mt="5%" mb="5%">
        Thanks for being awesome!
      </ParagraphOne>
      <ParagrapTwo
        id="simple-modal-description"
        fontSize="15px"
        mb="0px"
        ta="center"
        color="#0f3323"
      >
        We have received your message and would
      </ParagrapTwo>
      <ParagrapTwo
        id="simple-modal-description"
        fontSize="15px"
        mb="0px"
        ta="center"
        color="#0f3323"
      >
        like to thank you for writing to us. We will
      </ParagrapTwo>
      <ParagrapTwo
        id="simple-modal-description"
        fontSize="15px"
        mb="5%"
        ta="center"
        color="#0f3323"
      >
        reply by email as soon as possible.
      </ParagrapTwo>
      <Container width="100%" ta="center">
        <Button onClick={handleClose} margin="auto" bgColor="#0f3323">
          Accept
        </Button>
      </Container>
    </div>
  );
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [animate, setAnimate] = useState(false);

  const handleScroll = () => {
    setScrollY(window.scrollY);
    //console.log(scrollY);
    if (scrollY < 3650) {
      set(false);
    } else {
      setAnimate(true);
      set(true);
      //console.log(animate);
    }
    if (scrollY >= 4400) {
      setState(true);
    } else {
      setState(false);
    }
  };
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    window.onscroll = () => handleScroll();
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  function getDialogStyle() {
    return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
      border: 'none',
    };
  }

  const deviceDialogStyles = () => {
    if (isMobile) {
      return {
        root: {
          position: 'absolute',
          width: '85vw',
          height: '88vh',
          backgroundColor: '#FFA800',
          backgroundImage: 'url("./Group 381.png")',
          border: 'none',
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '15px 15px',
        },
      };
    }
    return {
      root: {
        position: 'absolute',
        width: '50vw',
        height: '60vh',
        backgroundColor: '#FFA800',
        backgroundImage: 'url("./Group 381.png")',
        border: 'none',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 60px',
      },
    };
  };

  const useDialogStyles = makeStyles(() => deviceDialogStyles());

  const useBackdropStyles = makeStyles({
    root: {
      backgroundColor: 'rgba(14,29,57,0.7)',
      backdropFilter: 'blur(5px)',
    },
  });

  const backdropClasses = useBackdropStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogStyle] = React.useState(getDialogStyle);
  const dialogClasses = useDialogStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const bodyDialog = () => (
    <div className={dialogClasses.root} style={dialogStyle}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <h1
          style={{
            fontFamily: 'DM Sans',
            fontSize: '40px',
            margin: '0px 0 20px',
            textAlign: 'center',
          }}
        >
          ¡Evolucionamos!
        </h1>
        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Nos emociona que seas parte de esto...
        </p>
        <h3
          style={{
            fontFamily: 'DM Sans',
            fontSize: '24px',
            margin: '0',
            textAlign: 'center',
          }}
        >
          Alimx evoluciona a Rocket Code
        </h3>
        <p
          style={{
            fontFamily: 'DM Sans',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Somos el mismo equipo que conoces pero con una nueva imagen, que
          gracias a tu confianza hemos logrado.
        </p>
        <a
          style={{
            fontFamily: 'DM Sans',
            fontSize: '24px',
            color: 'inherit',
            fontWeight: '700',
            margin: '30px',
            textDecoration: 'none',
            borderBottom: '1px solid white',
            textAlign: 'center',
          }}
          href="https://therocketcode.com/letter"
        >
          Palabras de nuestro CEO
        </a>
        <BtnGeneral
          href="https://therocketcode.com/"
          style={{ margin: '5px 30px 20px' }}
        >
          DESCUBRIR ROCKET CODE
        </BtnGeneral>
      </div>
    </div>
  );

  const updateEvent = (name) => {
    const tagManagerArgs = {
      gtmId: 'GTM-KKMSN3M',
      dataLayer: {
        event: name,
      },
    };
    TagManager.initialize(tagManagerArgs);
  };

  return (
    <Fragment>
      <Modal
        onClose={handleCloseDialog}
        open={openDialog}
        BackdropComponent={Backdrop}
        BackdropProps={{ classes: { root: backdropClasses.root } }}
      >
        {bodyDialog()}
      </Modal>
      <ButtonWhatsApp
        borderRadius="0 25px 25px 0"
        position="fixed"
        bottom="2%"
        textAlign="end"
        padding="5px 12px"
        isHover={isHover}
        onEnter={() => setIsHover(true)}
        onLeave={() => setIsHover(false)}
        onFocus={() => setIsHover(true)}
        onBlur={() => setIsHover(false)}
        onClick={() => updateEvent('contact-wa')}
        content={<h2 style={{ margin: '0px' }}>55 2141 9331</h2>}
      >
        <LogoWhats />
      </ButtonWhatsApp>
      <Main
        id="divStart"
        className="d-flex justify-content-center align-items-center"
      >
        <Container mb="3%" mt="2%" mtMobile="0%">
          <Col8 margin="auto">
            {window.screen.width < 600 ? (
              <>
                <div className="trails-text">
                  Outsmart your
                  <br /> competition
                  <br /> with our
                  <br /> marketing
                  <br /> services.
                </div>
                <ParagrapTwo
                  fontSize="20px"
                  mb="10%"
                  mt="5%"
                  mtMobile="10%"
                  fontSizeMobile="18px"
                >
                  Take your business <br /> model to new
                  <br /> heights.
                </ParagrapTwo>
              </>
            ) : (
              <>
                <div className="trails-text">
                  Outsmart your
                  <br /> competition with
                  <br /> our top-notch digital
                  <br /> marketing services and
                  <br /> platforms.
                </div>
                <ParagrapTwo
                  fontSize="20px"
                  mb="10%"
                  mt="5%"
                  mtMobile="10%"
                  fontSizeMobile="23px"
                >
                  Take your business model to new heights.
                </ParagrapTwo>
              </>
            )}
            <TriangleMobile>
              <img
                src={ImageTriangleMobile}
                style={{ maxWidth: 'inherit', width: '100%' }}
                alt="icon"
              />
            </TriangleMobile>
            <ButtonLink
              href="#divForm"
              display="flex"
              padding="10px"
              alingItems="center"
              justifyContent="center"
              bgColor="none"
              color="#D2E0D9"
              border="1px solid #D2E0D9"
              width="50%"
              wm="100%"
              mtm="20% !important"
              mbm="15% !important"
              onClick={() => updateEvent('click-form-body')}
            >
              Let’s growth together!
            </ButtonLink>
          </Col8>
          <Col2>
            <ContainerImagenCode>
              <img
                src={ImageLogo}
                style={{ maxWidth: 'inherit', width: '100%' }}
              />
            </ContainerImagenCode>
          </Col2>
          <BackTriangle>
            <img
              src={ImageBackTriangle}
              style={{ maxWidth: 'inherit', width: '100%' }}
              alt="icon"
            />
          </BackTriangle>
          <Triangle>
            <img
              src={ImageTriangle}
              style={{ maxWidth: 'inherit', width: '100%' }}
              alt="icon"
            />
          </Triangle>
        </Container>
      </Main>
      <Main background="rgb(248,249,251)">
        <Container mb="3%" mt="3%">
          <Col6>
            <Image
              src={ImageTwo}
              mlMobile="25%!important"
              mtMobile="10%!important"
              mbMobile="10%!important"
              dm="none"
            ></Image>
            <Chip>
              <img src={ImageChip} alt="icon" style={{ width: '100%' }} />
            </Chip>
          </Col6>
          <Col6 margin="auto">
            <ParagrapTwo
              fontSize="20px"
              fontWeight="bold"
              color="#1A6745"
              ff="'Maitree', serif"
              mtMobile="10%"
            >
              OUR VALUE PROPOSAL
            </ParagrapTwo>
            <ParagraphOne fontSize="31px" lh="35px" lhmobile="33px">
              We will crack your acquisition
            </ParagraphOne>
            <ParagraphOne mt="0px" fontSize="31px" lh="35px" lhmobile="33px">
              {' '}
              cost through innovative digital{' '}
            </ParagraphOne>
            <ParagraphOne mt="0px" fontSize="31px" lh="35px" lhmobile="33px">
              solutions.
            </ParagraphOne>
            <ParagrapTwo
              fontSize="20px"
              ta="justify"
              mt="5%"
              mbMobile="10%"
              mtMobile="10%"
              widthmax="60%"
              widthmax2="55%"
              widthmax3="90%"
              widthmax4="85%"
              widthmax5="80%"
              widthmax6="75%"
              color="#303A53"
            >
              Get more traction, more users, more customers. We unveil
              scalability by understanding your business goals and stablishing
              bullet proof methodologies to increase your marketing performance.
            </ParagrapTwo>
          </Col6>
          <Col12 id="whatWeDo" pt="10%" style={{ textAlign: 'center' }}>
            <ParagrapTwo
              fontSize="20px"
              fontWeight="bold"
              ta="center"
              color="#1A6745"
              ff="'Maitree', serif"
              tam="left"
            >
              WHAT WE DO
            </ParagrapTwo>
            <ParagraphOne fontSize="35px" mt="25px" ta="center" tam="left">
              Digital marketing services and platforms.
            </ParagraphOne>
            <ParagrapTwo
              fontSize="20px"
              mt="3%"
              mtMobile="10%"
              ta="center"
              color="#303A53"
              tam="left"
            >
              Our digital marketing experts will find innovative solutions based
              on our different types of marketing strategies to help you win the
              game!
            </ParagrapTwo>
            <WhatWeDo>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={ProductStrategyMobile}
                    alt="icon"
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Product strategy
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    Our strategists will help you understand the market, the
                    user and the product for you to take over the industry.
                  </ParagrapTwo>
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div style={{ marginRight: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Digital Acquisition
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    In today’s market, a robust user acquisition and growth
                    strategy are critical to build a scalable, profitable, and
                    self-sustainable business.
                  </ParagrapTwo>
                </div>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={DigitalAdquisicionMobile}
                    alt="icon"
                  />
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={ContentMarketingMobile}
                    alt="icon"
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Content Marketing
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    Through each stage, our team makes sure that your content is
                    driving traffic, optimized for online audiences and spurring
                    users to action.
                  </ParagrapTwo>
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div style={{ marginRight: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Performance Analysis
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    We develop a strategy targeting high conversion areas of the
                    market unique to your industry.
                  </ParagrapTwo>
                </div>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={PerformanceAnalysisMobile}
                    alt="icon"
                  />
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={PredictiveMarketingMobile}
                    alt="icon"
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Predictive Marketing
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    By the strategic use of existing customer datasets we
                    identify patterns to anticipate future customer behaviors
                    and outcomes.
                  </ParagrapTwo>
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div style={{ marginRight: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Marketing Automation
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    From strategy and execution to analytics and reporting,
                    count on us to help build your digital program and achieve
                    your priority objectives.
                  </ParagrapTwo>
                </div>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={MarketingAutomationMobile}
                    alt="icon"
                  />
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 56 }}>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={ReferralProgramsMobile}
                    alt="icon"
                  />
                </div>
                <div style={{ marginLeft: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Referral Programs
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    We build referrals into your process, and then let momentum
                    take over as they power your acquisition engine.
                  </ParagrapTwo>
                </div>
              </Flex>
              <Flex display="inline-flex" style={{ marginBottom: 140 }}>
                <div style={{ marginRight: 20 }}>
                  <ParagraphOne
                    mt="0px"
                    fontSizeMobile="18px"
                    ta="left"
                    color="#00446A"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Data & Analytics
                  </ParagraphOne>
                  <ParagrapTwo
                    lh="14px"
                    fontSizeMobile="12px"
                    ta="left"
                    color="#303A53"
                  >
                    Drive future growth and solve complex business challenges
                    using proven data analytics methods and digital analytics
                    best practices.
                  </ParagrapTwo>
                </div>
                <div>
                  <img
                    style={{ maxWidth: 77, width: 77 }}
                    src={DataAnalyticsMobile}
                    alt="icon"
                  />
                </div>
              </Flex>
            </WhatWeDo>
          </Col12>

          <ContainerServices pt="150px">
            <ContainerCardsServices>
              <CardServices bg="#23671A" bblr="50px">
                <Image
                  src={DataAnalytics}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Data & Analytics
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  Drive future growth and solve complex business challenges
                  using proven data analytics methods and digital analytics best
                  practices.
                </ParagrapTwo>
              </CardServices>
              <CardServices
                bg="#0B75B0"
                bbrr="100px"
                btlr="50px"
                bbrrlap="20px"
                bbrrlapl="50px"
                ht="290px"
              >
                <Image
                  src={ContentMarketing}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Content Marketing
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  Through each stage, our team makes sure that your content is
                  driving traffic, optimized for online audiences and spurring
                  users to action.
                </ParagrapTwo>
              </CardServices>
            </ContainerCardsServices>
            <ContainerCardsServices mt="-50px">
              <CardServices bg="#00446A" btlr="50px">
                <Image
                  src={ReferralPrograms}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                  top="15px"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Referral Programs
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  We build referrals into your process, and then let momentum
                  take over as they power your acquisition engine.
                </ParagrapTwo>
              </CardServices>
              <CardServices bg="#12483B" bblr="100px" bbrr="100px" ht="270px">
                <Image
                  src={MarketingAutomation}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Marketing Automation
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  From strategy and execution to analytics and reporting, count
                  on us to help build your digital program and achieve your
                  priority objectives.
                </ParagrapTwo>
              </CardServices>
            </ContainerCardsServices>
            <ContainerCardsServices mt="100px">
              <CardServices bg="#206F9B" btlr="120px" btrr="120px">
                <Image
                  src={DigitalAdquisicion}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Digital Acquisition
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  In today’s market, a robust user acquisition and growth
                  strategy are critical to build a scalable, profitable, and
                  self-sustainable business.
                </ParagrapTwo>
              </CardServices>
              <CardServices
                bg="#184812"
                btrr="55px"
                bblr="60px"
                left="50px"
                position="relative"
                ht="270px"
              >
                <Image
                  src={ProductStrategy}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Product strategy
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  Our strategists will help you understand the market, the user
                  and the product for you to take over the industry.
                </ParagrapTwo>
              </CardServices>
            </ContainerCardsServices>
            <ContainerCardsServices mt="40px" ml="50px">
              <CardServices
                bg="#303A53"
                btrr="120px"
                left="-50px"
                position="relative"
              >
                <Image
                  src={PerformanceAnalysis}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Performance Analysis
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  We develop a strategy targeting high conversion areas of the
                  market unique to your industry.
                </ParagrapTwo>
              </CardServices>
              <CardServices bg="#1A6745" bbrr="30px">
                <Image
                  src={PredictiveMarketing}
                  width="25%"
                  heigth="auto"
                  heigthMobile="auto"
                  widthMobile="25%"
                  margin="auto"
                ></Image>
                <ParagraphOne
                  fontSize="18px"
                  mt="0px"
                  fontSizeMobile="16px"
                  ta="center"
                  color="white"
                  style={{ textTransform: 'uppercase' }}
                >
                  Predictive Marketing
                </ParagraphOne>
                <ParagrapTwo
                  fontSize="14px"
                  lh="16px"
                  fontSizeMobile="12px"
                  ta="center"
                  color="white"
                >
                  By the strategic use of existing customer datasets we identify
                  patterns to anticipate future customer behaviors and outcomes.
                </ParagrapTwo>
              </CardServices>
            </ContainerCardsServices>
          </ContainerServices>
        </Container>
      </Main>
      <BackDrone id="ourFootprint" background="#D2E0D9">
        <Container mb="3%" mt="3%">
          <Col6 className="drone">
            <Image
              src={ImageThre}
              mtMobile="20%!important"
              mlMobile="20%!important"
            ></Image>
            <Image
              src={ShadowDron}
              mtMobile="20%!important"
              mlMobile="20%!important"
              top="50px"
              mtMobile="10% !important"
            ></Image>
          </Col6>
          <Col6 margin="auto">
            <ParagrapTwo fontSize="20px" fontWeight="bold" color="#1A6745">
              WHO WE ARE
            </ParagrapTwo>
            <ParagraphOne
              fontSize="30px"
              lh="33px"
              lhmobile="28px"
              color="#00446A"
            >
              We have helped to deliver over
            </ParagraphOne>
            <ParagraphOne mt="0px" fontSize="30px" lh="33px" color="#00446A">
              3 million users to our clients
            </ParagraphOne>
            <ParagrapTwo
              width="80%"
              fontSize="20px"
              ta="justify"
              mt="10%"
              mtMobile="10%"
              mbMobile="5%"
              color="#303A53"
            >
              With over 3 years of experience multiple platforms and dozens of
              clients, we have refactured acquisition channels and deploy
              digital strategies to increase ROI on our clients marketing
              investment.
            </ParagrapTwo>
            <ParagraphOne
              fontSize="15px"
              lh="15px"
              lhmobile="15px"
              mbMobile="20%"
              fontSizeMobile="15px"
              color="#303A53"
            >
              WE DELIVER
            </ParagraphOne>
          </Col6>
        </Container>
      </BackDrone>
      <Main id="divForm" background="#303A53">
        <Container mb="3%" mt="3%">
          <Col6 margin="auto">
            {window.screen.width > 800 ? (
              <Image
                src={ImagePhone}
                mtMobile="10%!important"
                mlMobile="20%!important"
                top="10%"
                width="auto"
              ></Image>
            ) : (
              <Flex display="inline-flex">
                {' '}
                <Image
                  src={ImagePhone}
                  mtMobile="10%!important"
                  mlMobile="0%!important"
                  top="10%"
                  width="auto"
                ></Image>{' '}
                <ParagraphOne
                  fontSizeMobile="25px"
                  lh="33px"
                  mt="20px"
                  pl="1%"
                  color="white"
                >
                  <animated.div
                    style={{
                      transform: x
                        .interpolate({
                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                          output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                        })
                        .interpolate((x) => `scale(${x})`),
                    }}
                  >
                    Let´s growth together!
                  </animated.div>
                </ParagraphOne>
              </Flex>
            )}
          </Col6>
          <Col6 margin="auto">
            {window.screen.width > 800 ? (
              <ParagraphOne
                fontSize="35px"
                lh="33px"
                mt="20px"
                pl="1%"
                color="white"
              >
                <animated.div
                  style={{
                    transform: x
                      .interpolate({
                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                        output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
                      })
                      .interpolate((x) => `scale(${x})`),
                  }}
                >
                  Let´s growth together!
                </animated.div>
              </ParagraphOne>
            ) : null}
            <form autoComplete="off" onSubmit={guardar}>
              <Col12>
                <TextField
                  id="full_name"
                  name="full_name"
                  error={error.full_name}
                  label="Name"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={form.full_name}
                  onChange={handleChange}
                  helperText={textError.full_name}
                />
              </Col12>
              <Flex>
                <Col6 style={{ marginTop: 30 }}>
                  <TextField
                    id="whatsapp"
                    name="whatsapp"
                    inputProps={{ maxLength: 10 }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    error={error.whatsapp}
                    label="Phone"
                    type="tel"
                    variant="outlined"
                    fullWidth
                    value={form.whatsapp}
                    onChange={handleChange}
                    helperText={textError.whatsapp}
                  />
                </Col6>
                <Col6 style={{ marginTop: 30 }}>
                  <TextField
                    id="email"
                    name="email"
                    error={error.email}
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={form.email}
                    onChange={handleChange}
                    helperText={textError.email}
                  />
                </Col6>
              </Flex>
              <Flex>
                <Col6 style={{ marginTop: 30 }}>
                  <TextField
                    id="company"
                    name="company"
                    error={error.company}
                    label="Company"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={form.company}
                    onChange={handleChange}
                    helperText={textError.company}
                  />
                </Col6>
                <Col6 style={{ marginTop: 30 }}>
                  <TextField
                    id="role"
                    name="role"
                    error={error.role}
                    label="Role"
                    type="text"
                    variant="outlined"
                    fullWidth
                    value={form.role}
                    onChange={handleChange}
                    helperText={textError.role}
                  />
                </Col6>
              </Flex>
              <Col12>
                <TextField
                  id="serie"
                  name="serie"
                  error={error.serie}
                  select
                  label="Select the service of your interest"
                  value={currency}
                  value={form.serie}
                  helperText={textError.serie}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Col12>
              <Col12 style={{ display: 'none' }}>
                <Flex
                  style={{
                    border: '1px solid rgb(26 103 69)',
                    padding: '5px 0px 5px 5px',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedA}
                        onChange={handleChangeCheck}
                        name="checkedA"
                      />
                    }
                    label=""
                  />
                  <ParagrapTwo mb="0px">
                    {' '}
                    I have read and agree with the{' '}
                    <a
                      href="#"
                      style={{
                        color: 'rgb(26 103 69)',
                        textDecoration: 'none',
                      }}
                    >
                      privacy policy
                    </a>
                  </ParagrapTwo>
                </Flex>
              </Col12>
              <Col12 display="flex">
                <Button
                  disabled={btndisabled}
                  width="70%"
                  ml="15%"
                  widthMobile="100%"
                  float="right"
                  bgColor="#D2E0D9"
                  color="#1A6745"
                  mtm="5% !important"
                  onClick={() => updateEvent('Submit')}
                >
                  Submit
                </Button>
              </Col12>
            </form>
          </Col6>
        </Container>
      </Main>
      <Main id="divFooter" background="#fff">
        <Container mb="3%" mt="3%">
          <Col6>
            <Image
              src={Isotipo}
              mtMobile="10%!important"
              mlMobile="35%!important"
              width="auto"
              margin="inherit"
              widthMobile="20%"
            ></Image>
            <ParagrapTwo
              color="#00446A"
              mt="30px"
              mtMobile="10px"
              tam="center"
              mbMobile="30px"
            >
              contact@ausangrowth.com
            </ParagrapTwo>
          </Col6>
          <Col6 margin="auto">
            <Flex display="inline-flex" jct="space-around">
              <a
                href=" https://www.facebook.com/Ausan-Growth-107244421459996"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Image
                  src={Face}
                  mtMobile="10%!important"
                  mlMobile="20%!important"
                  width="auto"
                  margin="inherit"
                ></Image>
              </a>
              <a
                href="https://www.instagram.com/ausangrowth/"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Image
                  src={Insta}
                  mtMobile="10%!important"
                  mlMobile="20%!important"
                  width="auto"
                  margin="inherit"
                ></Image>
              </a>
              <a
                href="https://www.linkedin.com/company/ausan-growth/about/?viewAsMember=true"
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Image
                  src={Linkedin}
                  mtMobile="10%!important"
                  mlMobile="20%!important"
                  width="auto"
                  margin="inherit"
                ></Image>
              </a>
            </Flex>
            <ParagrapTwo
              fontSizeMobile="12px"
              color="#00446A"
              mt="30px"
              ta="right"
              tam="center"
              mtMobile="20px"
              mbMobile="30px"
            >
              © Copyright 2021 Ausangrowth. All rights reserved.
            </ParagrapTwo>
          </Col6>
        </Container>
      </Main>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </Fragment>
  );
};

export default Home;
