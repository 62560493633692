import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import Home_2 from './components/Home_2';
import Header from './components/Header';
import TagManager from 'react-gtm-module';
function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-KKMSN3M',
  };
  TagManager.initialize(tagManagerArgs);
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Home2/" component={Home_2} />
      </Switch>
    </Router>
  );
}

export default App;
